/* .div{
    width:100%;
    display: block;
  margin-left: auto;
  margin-right: auto;
} */
/* img{ */
    /* display: inline-block; */
    /* height: 200px; */
    /* cursor: pointer; */
/* } */
.rest{
    margin-left: -40px;
    height: 100px;
}

.rest:hover{ 
    /* opacity: 50; */
    box-shadow: 0 10px 20px rgba(236, 16, 192, 0.12), 0 4px 8px rgba(188, 32, 240, 0.62);
    /* transform: translateY(-30px); */
    /* -webkit-transition:  box-shadow .6s ease-in; */
    /* top: 10px; */
    /* transition: top ease 0.5s; */
 }

 .mainFrameTwo{
    display:none;
 } 

/* w-[100%] h-[100%] hover:opacity-100 transition  duration-300 ease-in-out cursor-pointer opacity-100 hover:opacity-50	hover:-translate-y-7 */