.backgroundColor {
	background-color: #16191E;
}

.wrapper  {
  border: 0px solid green;
}

.squareID {
    width: 200px;
    height: 400px;
    position: absolute;
    /* background-color: #ee3c3c; */
    border: 0px solid black;
/*     box-shadow: 2px 2px 25px rgba(0, 0, 0, .5); */
}

.squareID {
  transition-property: transform;
  transform-origin: bottom center;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: initial;
}

/*1*/
.squareID.one {
  z-index: 10;
  background-color: #A5B452;
  transform: rotate(0deg);
  
}

/*2*/
.squareID.two {
  z-index: 7;
  background-color: #A5B452;
  transform: rotate(0deg);
}

/*3*/
.squareID.three {
  z-index: 6;
  background-color: #7BE0AD;
  transform: rotate(0deg);
}

/*4*/
.squareID.four {
  z-index: 7;
  background-color: #A5B452;
  transform: rotate(0deg);
}

/*5*/
.squareID.five {
  z-index: 6;
  background-color: #7BE0AD;
  transform: rotate(0deg);
}

.wrapper:hover {
  cursor: pointer;
}

.wrapper:focus {
  cursor: pointer;
}

/*first card*/
/* карта синяя слева */
.wrapper:hover > div:nth-child(1) {
  transform: rotate(-40deg);
  transform-origin: bottom center;
}

.wrapper:hover > #squareID #imageIcon {
  opacity: 1;
}

.wrapper:focus > div:nth-child(1) {
  transform: rotate(-15deg);
  transform-origin: bottom center;
  opacity: 1;
}

/*second card*/
/* карта синяя справа */
.wrapper:hover > div:nth-child(2) {
  transform: rotate(40deg);
  transform-origin: bottom center;
}

.wrapper:focus > div:nth-child(2) {
  transform: rotate(-25deg);
  transform-origin: bottom center;
}


/*third card*/
/* карта коричневая слева */

.wrapper:hover > div:nth-child(3) {
  transform: rotate(-25deg);
  transform-origin: bottom center;
}

.wrapper:focus > div:nth-child(3) {
  transform: rotate(-25deg);
  transform-origin: bottom center;
}

/*fourth card*/ 
/* карта коричневая справа */

.wrapper:hover > div:nth-child(4) {
  transform: rotate(25deg);
  transform-origin: bottom center;
}

.wrapper:focus > div:nth-child(4) {
  transform: rotate(25deg);
  transform-origin: bottom center;
}
